import {
    FILM_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    MUSIC_EVENTBRITE_CATEGORY,
    PARTY_EVENTBRITE_FORMAT,
    SEASONAL_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';
import {
    ArtsSmall as ArtsSmallSvg,
    CalendarNew as CalendarNewSvg,
    Cocktail as CocktailSvg,
    Disco as DiscoSvg,
    FilmMedia as FilmMediaSvg,
    GlobeSmall as GlobeSmallSvg,
    Halloween as HalloweenSvg,
    HolidayCalendar as HolidaySvg,
    MicSmall as MicSmallSvg,
} from '@eventbrite/eds-iconography';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import React from 'react';
import { OrganicEventSearchFiltersApiPayload } from '../../../api/searchOrganicEvents';

export const NIGHTLIFE = 'nightlife';
export const NIGHTLIFE_TAGS = [
    'EventbriteCategory/103',
    'EventbriteCategory/110',
    'EventbriteCategory/105',
    'EventbriteCategory/104',
    'EventbriteCategory/116',
    'EventbriteFormat/11',
    'EventbriteFormat/6',
    'EventbriteSubCategory/5010',
];

export const NightlifeTabsSearchQuery: {
    [key: string]: OrganicEventSearchFiltersApiPayload;
} = {
    All: {
        tags: [...NIGHTLIFE_TAGS],
    },
    Tonight: {
        tags: [...NIGHTLIFE_TAGS],
        dates: ['current_future', 'today'],
    },
    Music: {
        tags: [MUSIC_EVENTBRITE_CATEGORY],
    },
    ThisWeekend: {
        tags: [...NIGHTLIFE_TAGS],
        dates: ['current_future', 'this_weekend'],
    },
    ClubsAndParties: {
        tags: [PARTY_EVENTBRITE_FORMAT],
    },
    ComedyAndShows: {
        tags: ['EventbriteSubCategory/5010'],
    },
    FoodAndDrink: {
        tags: [FOOD_AND_DRINK_EVENTBRITE_CATEGORY],
    },
    FilmAndMedia: {
        tags: [FILM_EVENTBRITE_CATEGORY],
    },
    Halloween: {
        tags: ['EventbriteSubCategory/16004'],
    },
    Holiday: {
        tags: [SEASONAL_EVENTBRITE_CATEGORY],
    },
};

export interface CategoryTile {
    key: string;
    name: GenericLazyString;
    icon: any;
}

const SeasonalHoliday = {
    Halloween: {
        key: 'Halloween',
        name: gettext('Halloween'),
        icon: <HalloweenSvg />,
    },

    GenricHoliday: {
        key: 'Holiday',
        name: gettext('Holidays'),
        icon: <HolidaySvg viewBox="0 0 28 36" />,
    },
};

const Holiday = SeasonalHoliday['GenricHoliday'];

export const NightlifeTabs: CategoryTile[] = [
    {
        key: 'All',
        name: gettext('All'),
        icon: <GlobeSmallSvg />,
    },
    {
        key: 'Tonight',
        name: gettext('Tonight'),
        icon: <CalendarNewSvg />,
    },
    {
        key: 'ThisWeekend',
        name: gettext('This Weekend'),
        icon: <CalendarNewSvg />,
    },
    {
        key: 'Music',
        name: gettext('Music'),
        icon: <MicSmallSvg size="small" />,
    },
    {
        key: 'ClubsAndParties',
        name: gettext('Clubs & Parties'),
        icon: <DiscoSvg />,
    },
    Holiday,
    {
        key: 'ComedyAndShows',
        name: gettext('Comedy & Shows'),
        icon: <ArtsSmallSvg />,
    },
    {
        key: 'FoodAndDrink',
        name: gettext('Food & Drink'),
        icon: <CocktailSvg />,
    },
    {
        key: 'FilmAndMedia',
        name: gettext('Film & Media'),
        icon: <FilmMediaSvg />,
    },
];

export const CATEGORY_KEY_TO_URL_PARAM: { [key: string]: string } = {
    All: 'all',
    Tonight: 'tonight',
    ThisWeekend: 'this-weekend',
    Music: 'music',
    ClubsAndParties: 'clubs-and-parties',
    ComedyAndShows: 'comedy-and-shows',
    FoodAndDrink: 'food-and-drink',
    FilmAndMedia: 'film-and-media',
    Halloween: 'halloween',
    Holiday: 'holiday',
};

export const CATEGORY_URL_PARAM_TO_KEY: { [key: string]: string } = {
    all: 'All',
    tonight: 'Tonight',
    'this-weekend': 'ThisWeekend',
    music: 'Music',
    'clubs-and-parties': 'ClubsAndParties',
    'comedy-and-shows': 'ComedyAndShows',
    'food-and-drink': 'FoodAndDrink',
    'film-and-media': 'FilmAndMedia',
    halloween: 'Halloween',
    holiday: 'Holiday',
};

/**
 * These configs are used for
 * setting the sticky category container
 */

export const THROTTLE_TIME = 50; // ms
export const BUFFER = 10; // px

export const DEFAULT_NIGHTLIFE_TAB = 'All';
