import * as React from 'react';

const CalendarNewSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5 4a.5.5 0 01.5.5V5h8v-.5a.5.5 0 011 0V5h3.5a.5.5 0 01.5.5v22a.5.5 0 01-.5.5h-17a.5.5 0 01-.5-.5v-22a.5.5 0 01.5-.5H11v-.5a.5.5 0 01.5-.5zM11 6H8v4h2.5a.5.5 0 010 1H8v16h16V11H13.5a.5.5 0 010-1H24V6h-3v.5a.5.5 0 01-1 0V6h-8v.5a.5.5 0 11-1 0V6zm.5 9a.5.5 0 111 0 .5.5 0 01-1 0zm.5-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm0 5a.5.5 0 100 1 .5.5 0 000-1zm-1.5.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm1 4a.5.5 0 111 0 .5.5 0 01-1 0zm.5-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm4-7a.5.5 0 100 1 .5.5 0 000-1zm-1.5.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm1 4a.5.5 0 111 0 .5.5 0 01-1 0zm.5-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm0 5a.5.5 0 100 1 .5.5 0 000-1zm-1.5.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm5-8a.5.5 0 111 0 .5.5 0 01-1 0zm.5-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm0 5a.5.5 0 100 1 .5.5 0 000-1zm-1.5.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm1 4a.5.5 0 111 0 .5.5 0 01-1 0zm.5-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
            fill="#0D0029"
        />
    </svg>
);

CalendarNewSvg.displayName = 'CalendarNewSvg';
export default CalendarNewSvg;
