import {
    DATING_EVENTBRITE_SUB_CATEGORY,
    FORMATTED_SUBCATEGORY_MAP,
    NIGHTLIFE_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';
import { GenericLazyString } from '@eventbrite/i18n';
import { PARENT_CATEGORY_CONTENT_MAP } from './categoryImages';
import { DEFAULT_NIGHTLIFE_TAB } from './nightLife';

export interface CategoryContent {
    title: string | GenericLazyString;
    key: string;
    imageUrl: string;
    imageUrlSmall: string;
    subtitle?: string | GenericLazyString;
    theme?: string;
}

export const CATEGORY_CONTENT_MAP: Record<string, CategoryContent> = {
    ...PARENT_CATEGORY_CONTENT_MAP,
    ...FORMATTED_SUBCATEGORY_MAP,
};

// categoryBrowseEvents file
export const DEFAULT_SCREEN = 'category-browse';

export const TAG_TO_SCREEN_MAPPING: { [key: string]: string } = {
    [DATING_EVENTBRITE_SUB_CATEGORY]: 'dating',
    [NIGHTLIFE_EVENTBRITE_CATEGORY]: 'nightlife',
};

export const TAG_TO_DEFAULT_CATEGORY_MAPPING: { [key: string]: string } = {
    [DATING_EVENTBRITE_SUB_CATEGORY]: 'All',
    [NIGHTLIFE_EVENTBRITE_CATEGORY]: DEFAULT_NIGHTLIFE_TAB,
};
