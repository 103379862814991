import * as React from 'react';

const GlobeSmallSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.38 13.23l-1.362-1.363a6.879 6.879 0 00.79 4.939 9.13 9.13 0 01.572-3.577zm1.752-5.784a6.976 6.976 0 00-1.243.98 6.91 6.91 0 00-1.52 2.287l1.479 1.477c.415-.787.95-1.53 1.613-2.192.38-.38.789-.71 1.214-1.009l-1.543-1.543zm7.044-.104l.094.004a6.911 6.911 0 00-6.15-.42l1.482 1.483a9.097 9.097 0 014.293-1.076c.093 0 .187.005.281.01zm1.517 7.159l1.483 1.482c.347-.832.529-1.733.529-2.663a6.87 6.87 0 00-.947-3.484 9.103 9.103 0 01-1.065 4.665zm-2.302 5.232a6.905 6.905 0 002.287-1.519c.379-.38.702-.798.98-1.242l-1.54-1.54a9.068 9.068 0 01-3.2 2.827l1.473 1.474zm-5.987-.434l-.107-.004a6.875 6.875 0 004.94.792l-1.366-1.366a9.128 9.128 0 01-3.199.587c-.09 0-.179-.004-.268-.009zm5.949-3.41c.377-.377.71-.787 1-1.222l-4.919-4.919a8.007 8.007 0 00-2.79 3.237l4.474 4.475a8.019 8.019 0 002.235-1.57zm-2.955-6.683l4.501 4.501a8.057 8.057 0 00.637-5.138 7.995 7.995 0 00-5.139.638zm-.359 8.682l-3.819-3.819a8.058 8.058 0 00-.19 4.004 8.123 8.123 0 004.01-.185zm6.392 1.08a7.96 7.96 0 01-5.648 2.34 7.987 7.987 0 110-15.974 7.987 7.987 0 015.648 13.635zm1.72-13.287c4.07 4.07 4.069 10.668 0 14.738-1.899 1.898-4.171 2.9-6.835 3.026v2.157h1.598v1.066h-4.261v-1.066h1.598v-2.157c-2.664-.125-4.938-1.126-6.838-3.026l.755-.754c3.648 3.648 9.583 3.648 13.23 0 3.648-3.648 3.648-9.582 0-13.23l.753-.754z"
            fill="#0D0029"
        />
    </svg>
);

GlobeSmallSvg.displayName = 'GlobeSmallSvg';
export default GlobeSmallSvg;
