import {
    DestinationEvent,
    FormattedEvent,
    transformDestinationEvent,
} from '@eventbrite/event-renderer';
import {
    QueryKey,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from '@tanstack/react-query';
import {
    PromotedEventsSearchApiPayload,
    searchPromotedEvents,
} from './searchPromotedEvents';

export type UsePromotedEventsProps = {
    interfaceName: string;
    slots: number;
    search: Pick<PromotedEventsSearchApiPayload, 'search' | 'page'>;
} & ReactQueryExtraOptions;

export type ReactQueryExtraOptions = Omit<
    UseQueryOptions<DestinationEvent[], unknown, FormattedEvent[], any[]>,
    'select' | 'queryKey' | 'queryFn' | 'staleTime'
>;

export function usePromotedEvents({
    search = { search: {}, page: 1 },
    interfaceName,
    slots = 1,
    ...reactQueryProps
}: UsePromotedEventsProps) {
    const apiPayload = { slots, interface: interfaceName, ...search };
    const queryKey = [interfaceName, 'promoted', apiPayload];

    const { removeEvent } = useRemovePromotedEvent(queryKey);
    const query = useQuery({
        queryKey,
        queryFn: () => searchPromotedEvents(apiPayload),
        staleTime: 0,
        select: (events) => events.map(transformDestinationEvent),
        ...reactQueryProps,
    });

    return {
        ...query,
        removeEvent,
    };
}

// Due to legal compliance, we need to be able to hide promoted events from the UI
export function useRemovePromotedEvent(queryKey: QueryKey) {
    const client = useQueryClient();

    return {
        removeEvent: (eventId: string) => {
            client.setQueryData<DestinationEvent[]>(
                queryKey,
                (apiResponse = []) =>
                    apiResponse.filter(({ id }) => eventId !== id),
            );
        },
    };
}
