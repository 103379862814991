import * as React from 'react';

const MicSmallSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.25 4a6.25 6.25 0 00-3.764 11.24 2 2 0 003.392 1.71l.445-.444 9.858 8.375a.5.5 0 00.675-.026l.415-.41c.395.345.912.555 1.479.555.44 0 .851-.127 1.198-.345l3.198 3.199a.5.5 0 00.708-.708l-3.199-3.198A2.24 2.24 0 0025 22.75c0-.558-.204-1.07-.54-1.462l.437-.433a.5.5 0 00.028-.68l-8.419-9.853.444-.443a2 2 0 00-1.71-3.393A6.241 6.241 0 0010.25 4zm12.5 20c-.288 0-.554-.097-.765-.262l1.761-1.744A1.25 1.25 0 0122.75 24zm-6.953-12.968l-1.317 1.317a.5.5 0 11-.707-.707l2.47-2.47a1 1 0 10-1.415-1.415l-7.07 7.072a1 1 0 001.414 1.414l2.457-2.458a.5.5 0 01.707.707l-1.304 1.304 9.447 8.026 3.384-3.35-8.066-9.44zM14.29 6.899A5.239 5.239 0 0010.19 5l2.959 3.023.972-.973c.055-.054.111-.105.17-.151zM8.95 5.162a5.234 5.234 0 00-2.041 1.04l3.947 3.97a.496.496 0 01.065.08l1.522-1.522-3.493-3.568zM6.2 6.908a5.234 5.234 0 00-1.038 2.04l3.572 3.489 1.493-1.494a.496.496 0 01-.083-.067L6.201 6.908zM5 10.188L5 10.25c0 1.625.738 3.078 1.899 4.041.046-.059.097-.115.151-.17l.978-.977L5 10.187zm11.853 3.958a.5.5 0 00-.708.708l1 1a.5.5 0 00.708-.708l-1-1z"
            fill="#0D0029"
        />
    </svg>
);

MicSmallSvg.displayName = 'MicSmallSvg';
export default MicSmallSvg;
