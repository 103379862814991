import * as React from 'react';

const DiscoSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={24} height={24} fill="none" {...props}>
        <circle cx={12.25} cy={14.25} r={9.25} stroke="#140237" />
        <rect x={10} y={2.5} width={4} height={2} rx={1} stroke="#140237" />
        <path
            d="M12 2.5V.75"
            stroke="#140237"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15 14c0 2.584-.359 4.901-.925 6.556-.284.83-.61 1.465-.949 1.883-.341.422-.64.561-.876.561-.235 0-.535-.139-.876-.561-.338-.418-.665-1.054-.949-1.883C9.859 18.9 9.5 16.583 9.5 14c0-2.584.359-4.901.925-6.556.284-.83.61-1.465.949-1.883.341-.422.64-.561.876-.561.235 0 .535.139.876.561.338.418.665 1.054.949 1.883C14.641 9.1 15 11.416 15 14z"
            stroke="#140237"
        />
        <path
            d="M5.65 12.303c-.098.627-.15 1.279-.15 1.947 0 5.109 3.022 9.25 6.75 9.25S19 19.359 19 14.25 15.978 5 12.25 5c-1.941 0-3.691 1.123-4.923 2.921M21.5 15H3M16 19H4.5M20.5 10.5h-12"
            stroke="#140237"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle cx={6.5} cy={10.5} r={0.5} fill="#140237" />
        <path
            d="M22.5 4c-.984 0-2.25-.781-2.25-2.5C20.25 3.219 18.984 4 18 4c.984 0 2.25.781 2.25 2.5 0-1.719 1.266-2.5 2.25-2.5z"
            stroke="#140237"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

DiscoSvg.displayName = 'DiscoSvg';
export default DiscoSvg;
