import { GenericLazyString, gettext } from '@eventbrite/i18n';
import React from 'react';
import './Carousel.scss';

interface CardContent {
    title: string | GenericLazyString;
    imageUrl: string;
    style?: 'horizontal' | 'vertical';
    imageLoading?: 'eager' | 'lazy';
}

export const CarouselCard = (props: CardContent) => {
    const {
        title,
        imageUrl,
        style = 'vertical',
        imageLoading = 'eager',
    } = props;
    return (
        <div className={`carousel--card carousel--card--${style}`}>
            <div className="carousel--card-image__wrapper">
                <img
                    src={imageUrl}
                    alt={title?.toString() + ' ' + gettext('events').toString()}
                    loading={imageLoading}
                />
            </div>
            <p className="carousel-card__title eds-text-weight--heavy">
                {title}
            </p>
        </div>
    );
};
